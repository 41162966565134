<template>
  <div class="home">
    <div class="pc">
      <div class="overview">
        <div class="w1200">
          <div class="top">
            <img class="logo" src="../assets/img/logo-black.svg" alt="">
            <div class="nav">
<!--              <span>-->
<!--                <a :href="docLink" target="_blank">Swap</a>-->
<!--              </span>-->
              <span>
                <a :href="docLink" target="_blank">{{ $t("home.home15") }}</a>
              </span>
              <span>
                <el-dropdown>
                  <a>{{ $t("bottom.bottom12") }}<i class="el-icon-arrow-down el-icon--right"></i></a>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item>
                      <span>
                        <a href="https://id.nabox.io/" target="_blank">{{ "Nabox ID" }}</a>
                      </span>
                    </el-dropdown-item>
                    <el-dropdown-item>
                      <span>
                        <a href="https://swap.nabox.io/" target="_blank">{{ "SwapBox" }}</a>
                      </span>
                    </el-dropdown-item>
                    <el-dropdown-item>
                      <span>
                        <a href="https://bridge.nerve.network/" target="_blank">{{ "Bridge" }}</a>
                      </span>
                    </el-dropdown-item>
                    <el-dropdown-item>
                      <span>
                        <a href="https://parabox.nabox.io/" target="_blank">{{ "ParaBox" }}</a>
                      </span>
                    </el-dropdown-item>
                    <el-dropdown-item>
                      <span>
                        <a href="https://id.nabox.io/paybox" target="_blank">{{ "PayBox" }}</a>
                      </span>
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </span>
              <span>
                <el-dropdown>
                  <a>{{ $t("home.home30") }}<i class="el-icon-arrow-down el-icon--right"></i></a>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item>
                      <span>
                        <a href="https://id.nabox.io/apps/submitToken" target="_blank">{{ $t("home.home17") }}</a>
                      </span>
                    </el-dropdown-item>
                    <el-dropdown-item>
                      <span>
                        <a href="https://id.nabox.io/apps/submitDApps" target="_blank">{{ $t("home.home16") }}</a>
                      </span>
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </span>
              <span>
                <a href="https://forum.nabox.io/" target="_blank">{{ $t("home.home26") }}</a>
              </span>
              <span>
                <a :href="url + 'litepaper.pdf'"  target="_blank">Litepaper</a>
              </span>
              <span @click="langChange">{{ lang === "cn" ? "English" : "中文" }}</span>
            </div>
          </div>
          <div class="content">
            <h3 :style="{fontSize: lang==='en' ? '32px' : '36px'}">{{ $t("home.home3") }}</h3>
            <p :style="{width: lang==='en' ? '41%' : '50%'}" >{{ $t("home.home4") }}</p>
            <div class="download-wrap">
              <div class="down-item down-load-link app-down-load down-play">
                <a href="https://play.google.com/store/apps/details?id=com.wallet.nabox" target="_blank"></a>
              </div>
              <div class="down-item down-load-link app-down-load down-apple">
                <a href="https://apps.apple.com/us/app/nabox-wallet/id6443821021" target="_blank"></a>
              </div>
              <div class="down-item down-load-link down-chrome">
                <a :href="pluginLink" target="_blank" class="border_10"></a>
              </div>
              <div class="down-item down-load-link app-down-load down-apk">
                <a href="https://github.com/naboxwallet/nabox" target="_blank"></a>
              </div>
              <div class="down-item down-load-link down-testflight">
                <a href="https://testflight.apple.com/join/P3ASFT8F" target="_blank" class="border_10"></a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="features w1200">
        <div class="feature1 feature-item">
          <div class="img-wrap">
            <feature1/>
          </div>
          <div class="info">
            <h3>{{ $t("home.home7") }}</h3>
            <p>{{ $t("home.home8") }}</p>
          </div>
        </div>
        <div class="feature2 feature-item">
          <div class="info">
            <h3>{{ $t("home.home9") }}</h3>
            <p>{{ $t("home.home10") }}</p>
          </div>
          <div class="img-wrap">
            <feature2/>
          </div>
        </div>
        <div class="feature3 feature-item">
          <div class="img-wrap">
            <feature3/>
          </div>
          <div class="info">
            <h3>{{ $t("home.home11") }}</h3>
            <p>{{ $t("home.home12") }}</p>
          </div>
        </div>
        <div class="feature4 feature-item">
          <div class="info">
            <h3>{{ $t("home.home13") }}</h3>
            <p>{{ $t("home.home14") }}</p>
          </div>
          <div class="img-wrap">
            <feature4/>
          </div>
        </div>
      </div>
      <div class="support-network">
        <div class="support-network-cont">
          <div class="text-center title-cont">{{ $t("partner.partner1") }}</div>
          <div class="network-describe">{{ $t("partner.partner2") }}</div>
          <div class="coin-list">
            <div class="coin-item" v-for="item in chainList" :key="item.chain">
              <div class="coin-item-img">
                <img :src="item.icon" alt="">
              </div>
              <p class="chain-name">
                {{ item.chain }}
              </p>
            </div>
            <i></i><i></i><i></i><i></i><i></i>
            <i></i><i></i><i></i><i></i><i></i>
          </div>
        </div>
      </div>
      <div class="road-map">
        <div class="bg"></div>
        <div class="size-36 text-center text-f8 mt-120 overflow-hidden">{{ $t("roadMap.roadMap1") }}</div>
        <div class="road-item_cont">
          <div class="road-item">
            <div class="road-item_title text-white">
              {{ `${$t("roadMap.roadMap2")}` }}
            </div>
            <div class="road-item_content">
              <div class="h-234 d-flex direction-column space-between text-33">
                <div class="d-flex space-between size-16">
                  <span class="width-277">{{ $t("roadMap.roadMap3") }}</span>
                  <span class="success-icon">
                    <img src="@/assets/img/success_icon.png" alt="">
                  </span>
                </div>
                <div class="d-flex space-between size-16">
                  <span class="width-277">{{ $t("roadMap.roadMap4") }}</span>
                  <span class="success-icon">
                    <img src="@/assets/img/success_icon.png" alt="">
                  </span>
                </div>
                <div class="d-flex space-between size-16">
                  <span class="width-277">{{ $t("roadMap.roadMap5") }}</span>
                  <span class="success-icon">
                    <img src="@/assets/img/success_icon.png" alt="">
                  </span>
                </div>
                <div class="d-flex space-between size-16">
                  <span class="width-277">{{ $t("roadMap.roadMap6") }}</span>
                  <span class="success-icon">
                    <img src="@/assets/img/success_icon.png" alt="">
                  </span>
                </div>
                <div class="d-flex space-between size-16">
                  <span class="width-277">{{ $t("roadMap.roadMap7") }}</span>
                  <span class="success-icon">
                    <img src="@/assets/img/success_icon.png" alt="">
                  </span>
                </div>
                <div class="d-flex space-between size-16">
                  <span class="width-277">{{ $t("roadMap.roadMap8") }}</span>
                  <span class="success-icon">
                    <img src="@/assets/img/success_icon.png" alt="">
                  </span>
                </div>
                <div class="d-flex space-between size-16">
                  <span class="width-277">{{ $t("roadMap.roadMap9") }}</span>
                  <span class="success-icon">
                    <img src="@/assets/img/success_icon.png" alt="">
                  </span>
                </div>
                <div class="d-flex space-between size-16">
                  <span class="width-277">{{ $t("roadMap.roadMap10") }}</span>
                  <span class="success-icon">
                    <img src="@/assets/img/success_icon.png" alt="">
                  </span>
                </div>
                <div class="d-flex space-between size-16">
                  <span class="width-277">{{ $t("roadMap.roadMap11") }}</span>
                  <span class="success-icon">
                    <img src="@/assets/img/success_icon.png" alt="">
                  </span>
                </div>
                <div class="d-flex space-between size-16">
                  <span class="width-277">{{ $t("roadMap.roadMap12") }}</span>
                  <span class="success-icon">
                    <img src="@/assets/img/success_icon.png" alt="">
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="road-item">
            <div class="road-item_title text-white">
              {{ `${$t("roadMap.roadMap2")}` }}
            </div>
            <div class="road-item_content">
              <div class="h-234 d-flex direction-column space-between text-33">
                <div class="d-flex space-between size-16">
                  <span class="width-277">{{ $t("roadMap.roadMap13") }}</span>
                  <span class="success-icon">
                    <img src="@/assets/img/success_icon.png" alt="">
                  </span>
                </div>
                <div class="d-flex space-between size-16">
                  <span class="width-277">{{ $t("roadMap.roadMap14") }}</span>
                  <span class="success-icon">
                    <img src="@/assets/img/success_icon.png" alt="">
                  </span>
                </div>
                <div class="d-flex space-between size-16">
                  <span class="width-277">{{ $t("roadMap.roadMap15") }}</span>
                  <span class="success-icon">
                    <img src="@/assets/img/success_icon.png" alt="">
                  </span>
                </div>
                <div class="d-flex space-between size-16">
                  <span class="width-277">{{ $t("roadMap.roadMap16") }}</span>
                  <span class="success-icon">
                    <img src="@/assets/img/success_icon.png" alt="">
                  </span>
                </div>
                <div class="d-flex space-between size-16">
                  <span class="width-277">{{ $t("roadMap.roadMap17") }}</span>
                  <span class="success-icon">
                    <img src="@/assets/img/success_icon.png" alt="">
                  </span>
                </div>
                <div class="d-flex space-between size-16">
                  <span class="width-277">{{ $t("roadMap.roadMap18") }}</span>
                  <span class="success-icon">
                    <img src="@/assets/img/success_icon.png" alt="">
                  </span>
                </div>
                <div class="d-flex space-between size-16">
                  <span class="width-277">{{ $t("roadMap.roadMap19") }}</span>
                  <span class="success-icon">
                    <img src="@/assets/img/success_icon.png" alt="">
                  </span>
                </div>
                <div class="d-flex space-between size-16">
                  <span class="width-277">{{ $t("roadMap.roadMap20") }}</span>
                  <span class="success-icon">
                    <img src="@/assets/img/success_icon.png" alt="">
                  </span>
                </div>
<!--                <div class="d-flex space-between size-16">-->
<!--                  <span class="width-277">{{ $t("roadMap.roadMap22") }}</span>-->
<!--                  <span class="success-icon">-->
<!--                    <img src="@/assets/img/success_icon.png" alt="">-->
<!--                  </span>-->
<!--                </div>-->
<!--                <div class="d-flex space-between size-16">-->
<!--                  <span class="width-277">{{ $t("roadMap.roadMap23") }}</span>-->
<!--                  <span class="success-icon">-->
<!--                    <img src="@/assets/img/success_icon.png" alt="">-->
<!--                  </span>-->
<!--                </div>-->
                <div class="d-flex space-between size-16">
                  <span class="width-277">{{ $t("roadMap.roadMap24") }}</span>
                  <span class="success-icon">
                    <img src="@/assets/img/success_icon.png" alt="">
                  </span>
                </div>
                <div class="d-flex space-between size-16">
                  <span class="width-277">{{ $t("roadMap.roadMap25") }}</span>
                  <span class="success-icon">
                    <img src="@/assets/img/success_icon.png" alt="">
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="road-item">
            <div class="road-item_title text-white">
              {{ 2024 }}
            </div>
            <div class="road-item_content">
              <div class="h-234 d-flex direction-column space-between text-33">
                <div class="d-flex space-between size-16">
                  <span class="width-277">{{ $t("roadMap.roadMap26") }}</span>
                  <span class="success-icon"></span>
                </div>
                <div class="d-flex space-between size-16">
                  <span class="width-277">{{ $t("roadMap.roadMap27") }}</span>
                  <span class="success-icon"></span>
                </div>
                <div class="d-flex space-between size-16">
                  <span class="width-277">{{ $t("roadMap.roadMap28") }}</span>
                  <span class="success-icon"></span>
                </div>
                <div class="d-flex space-between size-16">
                  <span class="width-277">{{ $t("roadMap.roadMap29") }}</span>
                  <span class="success-icon"></span>
                </div>
                <div class="d-flex space-between size-16">
                  <span class="width-277">{{ $t("roadMap.roadMap30") }}</span>
                  <span class="success-icon"></span>
                </div>
                <div class="d-flex space-between size-16">
                  <span class="width-277">{{ $t("roadMap.roadMap31") }}</span>
                  <span class="success-icon"></span>
                </div>
              </div>
            </div>
          </div>
          <div class="road-item">
            <div class="road-item_title text-white">
              {{ 2024 }}
            </div>
            <div class="road-item_content">
              <div class="h-234 d-flex direction-column space-between text-33">
                <div class="d-flex space-between size-16">
                  <span class="width-277">{{ $t("roadMap.roadMap32") }}</span>
                  <span class="success-icon"></span>
                </div>
                <div class="d-flex space-between size-16">
                  <span class="width-277">{{ $t("roadMap.roadMap33") }}</span>
                  <span class="success-icon"></span>
                </div>
                <div class="d-flex space-between size-16">
                  <span class="width-277">{{ $t("roadMap.roadMap34") }}</span>
                  <span class="success-icon"></span>
                </div>
                <div class="d-flex space-between size-16">
                  <span class="width-277">{{ $t("roadMap.roadMap35") }}</span>
                  <span class="success-icon"></span>
                </div>
                <div class="d-flex space-between size-16">
                  <span class="width-277">{{ $t("roadMap.roadMap36") }}</span>
                  <span class="success-icon"></span>
                </div>
                <div class="d-flex space-between size-16">
                  <span class="width-277">{{ $t("roadMap.roadMap37") }}</span>
                  <span class="success-icon"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="investors w1200">
        <h3>{{ $t("investors.investors1") }}</h3>
        <div class="info">
          <div class="item">
            <img src="../assets/img/okex.png" alt="">
          </div>
          <div class="item">
            <img src="../assets/img/LD.png" alt="">
          </div>
          <div class="item">
            <img src="../assets/img/Ventures.png" alt="">
          </div>
          <div class="item">
            <img src="../assets/img/bitgert.png" alt="">
          </div>
          <div class="item">
            <img src="../assets/img/omni.png" alt="">
          </div>
          <div class="item">
            <img src="../assets/img/PrimeBlock.png" alt="">
          </div>
          <div class="item">
            <img src="../assets/img/AC.png" alt="">
          </div>
          <div class="item">
            <img src="../assets/img/CATCHERVC.png" alt="">
          </div>
          <div class="item">
            <img src="../assets/img/third.png" alt="">
          </div>
          <div class="item">
            <img src="../assets/img/coinex.png" alt="">
          </div>
          <div class="item">
            <img src="../assets/img/JRRGroup.png" alt="">
          </div>
          <div class="item">
            <img src="../assets/img/69_ventures.jpg" alt="">
          </div>
          <div class="item">
            <img src="../assets/img/angelone.png" alt="">
          </div>
          <div class="item">
            <img src="../assets/img/7oclock.png" alt="">
          </div>
          <div class="item">
            <img src="../assets/img/DM.png" alt="">
          </div>
          <div class="item w-60">
            <img src="../assets/img/MR.png" alt="">
          </div>
          <div class="item">
            <img src="../assets/img/fund.png" alt="">
          </div>
          <div class="item">
            <img src="../assets/img/block_focus.png" alt="">
          </div>
          <div class="item w-60">
            <img src="../assets/img/crypto.png" alt="">
          </div>
          <div class="item">
            <img src="../assets/img/xstar.png" alt="">
          </div>
          <div class="item w-60">
            <img src="../assets/img/uk.jpeg" alt="">
          </div>
          <div class="item">
            <img src="../assets/img/77.png" alt="">
          </div>
          <div class="item">
            <img src="../assets/img/FFCAP.png" alt="">
          </div>
          <div class="item">
            <img src="../assets/img/OnegaVenture.png" alt="">
          </div>
          <div class="item w-60">
            <img src="../assets/img/Sharding.png" alt="">
          </div>
          <div class="item">
            <img src="../assets/img/bigone.png" alt="">
          </div>
          <div class="item">
            <img src="../assets/img/SCORPIO VC.png" alt="">
          </div>
          <div class="item">
            <img src="../assets/img/light_house.png" alt="">
          </div>
          <div class="item">
            <img src="../assets/img/chain_fir.png" alt="">
          </div>
        </div>
      </div>
      <div class="bottom">
        <div class="content w1200">
          <div class="logo-wrap">
            <img src="../assets/img/logo-white.svg" alt="">
          </div>
          <div class="doc">
            <h5>{{ $t("bottom.bottom5") }}</h5>
            <div>
              <p>
                <a :href="bottomDocLink1" target="_blank">
                  {{ $t("bottom.bottom6") }}
                </a>
              </p>
              <p>
                <a :href="bottomDocLink2" target="_blank">
                  {{ $t("bottom.bottom7") }}
                </a>
              </p>
            </div>
          </div>
          <div class="ecosystem">
            <h5>{{ $t("bottom.bottom12") }}</h5>
            <div>
              <p>
                <a href="https://id.nabox.io/" target="_blank">
                  {{ "Nabox ID" }}
                </a>
              </p>
              <p>
                <a href="https://swap.nabox.io/" target="_blank">
                  {{ "SwapBox" }}
                </a>
              </p>
              <p>
                <a href="https://bridge.nerve.network/" target="_blank">
                  {{ "Bridge" }}
                </a>
              </p>
              <p>
                <a href="https://parabox.nabox.io/" target="_blank">
                  {{ "ParaBox" }}
                </a>
              </p>
              <p>
                <a href="https://id.nabox.io/paybox" target="_blank">
                  {{ "PayBox" }}
                </a>
              </p>
            </div>
          </div>
          <div class="cooperation">
            <h5>{{ $t("bottom.bottom8") }}</h5>
            <div>
              <p>
                <a href="https://id.nabox.io/apps/submitToken" target="_blank">
                  {{ $t("bottom.bottom9") }}
                </a>
              </p>
              <p>
                <a href="https://id.nabox.io/apps/submitDApps" target="_blank">
                  {{ $t("bottom.bottom10") }}
                </a>
              </p>
              <p>
                <a href="https://drive.google.com/drive/u/2/folders/1wTbH80Mep1Fue9NKX2r925JB8EitkI0i" target="_blank">
                  {{ $t("bottom.bottom11") }}
                </a>
              </p>
            </div>
          </div>
          <div class="focus">
            <h5>{{ $t("bottom.bottom1") }}</h5>
            <div>
              <p>
                <a href="https://twitter.com/naboxwallet" target="_blank">
                  {{ $t("bottom.bottom2") }}
                </a>
              </p>
              <p>
                <a href="https://github.com/naboxwallet" target="_blank">
                  Github
                </a>
              </p>
              <p>
                <a href="https://medium.com/@naboxwallet" target="_blank">
                  {{ $t("bottom.bottom4") }}
                </a>
              </p>
              <p>
                <a href="https://t.me/naboxcommunity" target="_blank">
                  Telegram
                </a>
              </p>
              <p>
                <a href="https://discord.gg/mQVXZJXMkn" target="_blank">
                  {{ $t("bottom.bottom3") }}
                </a>
              </p>
              <p>
                <a href="https://forum.nabox.io/" target="_blank">
                  {{ $t("home.home26") }}
                </a>
              </p>
            </div>
          </div>
          <div class="copyright">
            <div>Nabox Labs</div>
            <div>Copyright 2018-{{ new Date().getFullYear() }} &copy;  All rights Reserved. Nabox</div>
          </div>
        </div>
      </div>
    </div>
    <div class="mobile" @click="showList=false">
      <div class="mobile-nav bg-white" :class="{fixed: hideMenu}">
        <div class="wrap">
          <img class="logo" src="../assets/img/logo-black.svg" alt="">
          <div class="icon" @click="toggleMenu">
            <img v-if="!hideMenu" src="../assets/img/menu.svg" alt="">
            <img class="close" v-else src="../assets/img/close.svg" alt="">
          </div>
        </div>
        <div v-if="hideMenu" class="menu">
<!--          <div>-->
<!--            <a :href="docLink" target="_blank">Swap</a>-->
<!--          </div>-->
          <div class="menu_nav">
            <a :href="docLink" target="_blank">{{ $t("home.home15") }}</a>
          </div>
          <el-collapse>
            <el-collapse-item>
              <template slot="title">
                {{ $t('bottom.bottom12') }}
              </template>
              <p class="mb-15">
                <a href="https://id.nabox.io/" target="_blank">
                  {{ "Nabox ID" }}
                </a>
              </p>
              <p class="mb-15">
                <a href="https://swap.nabox.io/" target="_blank">
                  {{ "SwapBox" }}
                </a>
              </p>
              <p class="mb-15">
                <a href="https://bridge.nerve.network/" target="_blank">
                  {{ "Bridge" }}
                </a>
              </p>
              <p class="mb-15">
                <a href="https://parabox.nabox.io/" target="_blank">
                  {{ "ParaBox" }}
                </a>
              </p>
              <p class="mb-15">
                <a href="https://id.nabox.io/paybox" target="_blank">
                  {{ "PayBox" }}
                </a>
              </p>
            </el-collapse-item>
          </el-collapse>
          <div class="menu_nav">
            <a href="https://id.nabox.io/apps/submitToken" target="_blank">{{ $t("home.home17") }}</a>
          </div>
          <div class="menu_nav">
            <a href="https://id.nabox.io/apps/submitDApps" target="_blank">{{ $t("home.home16") }}</a>
          </div>
          <div class="menu_nav">
            <a href="https://forum.nabox.io/" target="_blank">{{ $t("home.home26") }}</a>
          </div>
          <div class="menu_nav">
            <a :href="url + 'litepaper.pdf'" target="_blank">Litepaper</a>
          </div>
          <div class="menu_nav" @click="langChange">{{ lang === "cn" ? "English" : "中文" }}</div>
        </div>
      </div>
      <div class="overview-mobile bg-white">
        <template>
          <h3>{{ $t("home.home3") }}</h3>
          <div>
            <div style="margin-top: 30px">Add: Support the Taproot</div>
            <div style="margin-top: 10px">Add: Support the Nested Segwit</div>
            <div style="margin-top: 10px">Fix: Fix some bugs collected from community</div>
          </div>
          <p>{{ $t("home.home4") }}</p>
          <div class="btn-cont">
            <div class="btn mr-1 mb-15 down-apple">
              <a href="https://apps.apple.com/us/app/nabox-wallet/id6443821021"></a>
            </div>
            <div class="btn mr-1 mb-15 down-testflight">
              <a href="https://testflight.apple.com/join/P3ASFT8F"></a>
            </div>
            <div v-if="isAndroid || isPc" class="btn mr-1 mb-15 down-play">
              <a href="https://play.google.com/store/apps/details?id=com.wallet.nabox"></a>
            </div>
            <div v-if="isAndroid || isPc" class="btn mr-1 mb-15 down-apk">
              <a href="https://github.com/naboxwallet/nabox"/>
            </div>
            <div v-if="isAndroid || isPc" class="btn mr-1 mb-15 down-chrome">
              <a :href="pluginLink"/>
            </div>
            <i v-if="isAndroid || isPc"/><i v-if="isAndroid || isPc"/><i v-if="isAndroid || isPc"/><i v-if="isAndroid || isPc"/>
          </div>
          <img class="phone-bg" src="../assets/img/phone.png" alt="">
        </template>
      </div>
      <div class="features-mobile bg-gray">
        <div class="feature1 feature-item">
          <div class="img-wrap">
            <feature1 mobile="true"/>
          </div>
          <div class="info">
            <h3>{{ $t("home.home7") }}</h3>
            <p>{{ $t("home.home8") }}</p>
          </div>
        </div>
        <div class="feature-item">
          <div class="img-wrap">
            <feature2 mobile="true"/>
          </div>
          <div class="info">
            <h3>{{ $t("home.home9") }}</h3>
            <p>{{ $t("home.home10") }}</p>
          </div>
        </div>
        <div class="feature-item">
          <div class="img-wrap">
            <feature3 mobile="true"/>
          </div>
          <div class="info">
            <h3>{{ $t("home.home11") }}</h3>
            <p>{{ $t("home.home12") }}</p>
          </div>
        </div>
        <div class="feature-item">
          <div class="img-wrap">
            <feature4 mobile="true"/>
          </div>
          <div class="info">
            <h3>{{ $t("home.home13") }}</h3>
            <p>{{ $t("home.home14") }}</p>
          </div>
        </div>
      </div>
      <div class="partner-mobile bg-gray">
        <h3>{{ $t("partner.partner1") }}</h3>
        <div class="text-center mb_20">{{ $t("partner.partner2") }}</div>
        <div class="support-network_image">
          <div class="support-network_image-item" v-for="item in chainList" :key="item.chain">
            <img :src="item.icon" alt="">
          </div>
          <i></i><i></i><i></i><i></i><i></i>
          <i></i><i></i><i></i><i></i><i></i>
        </div>
      </div>
      <div class="road-map-mobile bg-white">
        <h3>{{ $t("roadMap.roadMap1") }}</h3>
        <div class="road-item">
          <h5>{{ $t("roadMap.roadMap2") }}</h5>
          <p>{{ $t("roadMap.roadMap3") }}</p>
          <p>{{ $t("roadMap.roadMap4") }}</p>
          <p>{{ $t("roadMap.roadMap5") }}</p>
          <p>{{ $t("roadMap.roadMap6") }}</p>
          <p>{{ $t("roadMap.roadMap7") }}</p>
          <p>{{ $t("roadMap.roadMap8") }}</p>
          <p>{{ $t("roadMap.roadMap9") }}</p>
          <p>{{ $t("roadMap.roadMap10") }}</p>
          <p>{{ $t("roadMap.roadMap11") }}</p>
          <p>{{ $t("roadMap.roadMap12") }}</p>
          <p>{{ $t("roadMap.roadMap13") }}</p>
          <p>{{ $t("roadMap.roadMap14") }}</p>
          <p>{{ $t("roadMap.roadMap15") }}</p>
          <p>{{ $t("roadMap.roadMap16") }}</p>
          <p>{{ $t("roadMap.roadMap17") }}</p>
          <p>{{ $t("roadMap.roadMap18") }}</p>
          <p>{{ $t("roadMap.roadMap19") }}</p>
          <p>{{ $t("roadMap.roadMap20") }}</p>
          <p>{{ $t("roadMap.roadMap24") }}</p>
          <p>{{ $t("roadMap.roadMap25") }}</p>
        </div>
        <div class="road-item">
          <h5>{{ 2024 }}</h5>
          <p>{{ $t("roadMap.roadMap26") }}</p>
          <p>{{ $t("roadMap.roadMap27") }}</p>
          <p>{{ $t("roadMap.roadMap28") }}</p>
          <p>{{ $t("roadMap.roadMap29") }}</p>
          <p>{{ $t("roadMap.roadMap30") }}</p>
          <p>{{ $t("roadMap.roadMap31") }}</p>
          <p>{{ $t("roadMap.roadMap32") }}</p>
          <p>{{ $t("roadMap.roadMap33") }}</p>
          <p>{{ $t("roadMap.roadMap34") }}</p>
          <p>{{ $t("roadMap.roadMap35") }}</p>
          <p>{{ $t("roadMap.roadMap36") }}</p>
          <p>{{ $t("roadMap.roadMap37") }}</p>
        </div>
      </div>
      <div class="investors-mobile bg-white">
        <h3>{{ $t("investors.investors1") }}</h3>
        <div class="info">
          <div class="item">
            <img src="../assets/img/okex.png" alt="">
          </div>
          <div class="item">
            <img src="../assets/img/LD.png" alt="">
          </div>
          <div class="item">
            <img src="../assets/img/Ventures.png" alt="">
          </div>
          <div class="item">
            <img src="../assets/img/bitgert.png" alt="">
          </div>
          <div class="item">
            <img src="../assets/img/omni.png" alt="">
          </div>
          <div class="item">
            <img src="../assets/img/PrimeBlock.png" alt="">
          </div>
          <div class="item">
            <img src="../assets/img/AC.png" alt="">
          </div>
          <div class="item">
            <img src="../assets/img/CATCHERVC.png" alt="">
          </div>
          <div class="item">
            <img src="../assets/img/third.png" alt="">
          </div>
          <div class="item">
            <img src="../assets/img/coinex.png" alt="">
          </div>
          <div class="item">
            <img src="../assets/img/JRRGroup.png" alt="">
          </div>
          <div class="item">
            <img src="../assets/img/69_ventures.jpg" alt="">
          </div>
          <div class="item">
            <img src="../assets/img/angelone.png" alt="">
          </div>
          <div class="item">
            <img src="../assets/img/7oclock.png" alt="">
          </div>
          <div class="item">
            <img src="../assets/img/DM.png" alt="">
          </div>
          <div class="item w-140">
            <img src="../assets/img/MR.png" alt="">
          </div>
          <div class="item">
            <img src="../assets/img/fund.png" alt="">
          </div>
          <div class="item">
            <img src="../assets/img/block_focus.png" alt="">
          </div>
          <div class="item w-140">
            <img src="../assets/img/crypto.png" alt="">
          </div>
          <div class="item">
            <img src="../assets/img/xstar.png" alt="">
          </div>
          <div class="item w-140">
            <img src="../assets/img/uk.jpeg" alt="">
          </div>
          <div class="item">
            <img src="../assets/img/77.png" alt="">
          </div>
          <div class="item">
            <img src="../assets/img/FFCAP.png" alt="">
          </div>
          <div class="item">
            <img src="../assets/img/OnegaVenture.png" alt="">
          </div>
          <div class="item w-140">
            <img src="../assets/img/Sharding.png" alt="">
          </div>
          <div class="item">
            <img src="../assets/img/bigone.png" alt="">
          </div>
          <div class="item">
            <img src="../assets/img/SCORPIO VC.png" alt="">
          </div>
          <div class="item">
            <img src="../assets/img/light_house.png" alt="">
          </div>
          <div class="item">
            <img src="../assets/img/chain_fir.png" alt="">
          </div>
        </div>
      </div>
      <div class="focus-mobile bg-white">
        <h3>{{ $t("bottom.bottom1") }}</h3>
        <div class="info">
          <div class="focus-item">
            <a class="twitter" href="https://twitter.com/naboxwallet" target="_blank"></a>
          </div>
          <div class="focus-item">
            <a class="github" href="https://github.com/naboxwallet" target="_blank"></a>
          </div>
          <div class="focus-item">
            <a class="medium" href="https://medium.com/@naboxwallet" target="_blank"></a>
          </div>
          <div class="focus-item">
            <a class="tme" href="https://t.me/naboxcommunity" target="_blank"></a>
          </div>
          <div class="focus-item">
            <a class="discord" href="https://discord.gg/mQVXZJXMkn" target="_blank"></a>
          </div>
          <div class="focus-item">
            <a class="nabox" href="https://forum.nabox.io/" target="_blank"></a>
          </div>
        </div>
      </div>
      <div class="doc-mobile bg-gray">
        <h3>{{ $t("bottom.bottom5") }}</h3>
        <div>
          <p>
            <a :href="bottomDocLink1" target="_blank">
              {{ $t("bottom.bottom6") }}
            </a>
          </p>
          <p>
            <a :href="bottomDocLink2" target="_blank">
              {{ $t("bottom.bottom7") }}
            </a>
          </p>
        </div>
      </div>
      <div class="cooperation-mobile bg-gray">
        <h3>{{ $t("bottom.bottom12") }}</h3>
        <div>
          <p>
            <a href="https://id.nabox.io/" target="_blank">
              {{ "Nabox ID" }}
            </a>
          </p>
          <p>
            <a href="https://swap.nabox.io/" target="_blank">
              {{ "SwapBox" }}
            </a>
          </p>
          <p>
            <a href="https://bridge.nerve.network/" target="_blank">
              {{ "Bridge" }}
            </a>
          </p>
          <p>
            <a href="https://parabox.nabox.io/" target="_blank">
              {{ "ParaBox" }}
            </a>
          </p>
          <p>
            <a href="https://id.nabox.io/paybox" target="_blank">
              {{ "PayBox" }}
            </a>
          </p>
        </div>
      </div>
      <div class="cooperation-mobile bg-gray">
        <h3>{{ $t("bottom.bottom8") }}</h3>
        <div>
          <p>
            <a href="https://id.nabox.io/apps/submitToken" target="_blank">
              {{ $t("bottom.bottom9") }}
            </a>
          </p>
          <p>
            <a href="https://id.nabox.io/apps/submitDApps" target="_blank">
              {{ $t("bottom.bottom10") }}
            </a>
          </p>
          <p>
            <a href="https://drive.google.com/drive/u/2/folders/1wTbH80Mep1Fue9NKX2r925JB8EitkI0i" target="_blank">
              {{ $t("bottom.bottom11") }}
            </a>
          </p>
        </div>
      </div>
      <div class="copyright_mobile">
        <p>Nabox Labs</p>
        <p>Copyright 2018-{{ new Date().getFullYear() }} &copy;  All rights Reserved. Nabox</p>
      </div>
    </div>
  </div>
</template>

<script>
import Feature1 from "../assets/img/svg/feature1"
import Feature2 from "../assets/img/svg/feature2"
import Feature3 from "../assets/img/svg/feature3"
import Feature4 from "../assets/img/svg/feature4"

export default {
  data() {
    this.url = window.location.href
    return {
      // key: value
      // lang: localStorage.getItem("lang") || "cn",
      hideMenu: false,
      showList: false,
      mobildDown: false,
      showPluginLink: false,
      isMobile: /Android|webOS|iPhone|iPad|BlackBerry/i.test(navigator.userAgent),
      showDropList: false,
      isAndroid: window.navigator.appVersion.match(/android/gi),
      isIPhone: window.navigator.appVersion.match(/iphone/gi),
      isPc: !(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)),
      chainList: [
        {"chain":"BTC","icon":"https://files.nabox.io/icon/BTC.png"},
        {"chain":"Ethereum","icon":"https://files.nabox.io/icon/Ethereum.png"},
        {"chain":"BSC","icon":"https://files.nabox.io/icon/BSC.png"},
        {"chain":"Arbitrum","icon":"https://files.nabox.io/icon/Arbitrum.png"},
        {"chain":"zkSync","icon":"https://files.nabox.io/icon/zksync.png"},
        {"chain":"Polygon","icon":"https://files.nabox.io/icon/Polygon.png"},
        {"chain":"Optimism","icon":"https://files.nabox.io/icon/Optimism.png"},
        {"chain":"Avalanche","icon":"https://files.nabox.io/icon/AVAX.png"},
        {"chain":"Heco","icon":"https://files.nabox.io/icon/Heco.png"},
        {"chain":"OKC","icon":"https://files.nabox.io/icon/OKExChain.png"},
        {"chain":"Cosmos","icon":"https://files.nabox.io/icon/cosmos.png"},
        {"chain":"TRON","icon":"https://files.nabox.io/icon/tron.png"},
        {"chain":"NULS","icon":"https://files.nabox.io/icon/Nuls.png"},
        {"chain":"ENULS","icon":"https://files.nabox.io/icon/ENULS.png"},
        {"chain":"NERVE","icon":"https://files.nabox.io/icon/NERVE.png"},
        {"chain":"ETC","icon":"https://files.nabox.io/icon/etc.png"},
        {"chain":"Conflux","icon":"https://files.nabox.io/icon/Conflux.png"},
        {"chain":"LTC","icon":"https://files.nabox.io/icon/ltc.png"},
        {"chain":"DOGE","icon":"https://files.nabox.io/icon/doge.png"},
        {"chain":"Fantom","icon":"https://files.nabox.io/icon/Fantom.png"},
        {"chain":"Harmony","icon":"https://files.nabox.io/icon/Harmony.png"},
        {"chain":"KCC","icon":"https://files.nabox.io/icon/KCC.png"},
        {"chain":"Cronos","icon":"https://files.nabox.io/icon/Cronos.png"},
        {"chain":"CSC","icon":"https://files.nabox.io/icon/CoinEx.jpeg"},
        {"chain":"IoTeX","icon":"https://files.nabox.io/icon/IoTeX.png"},
        {"chain":"Metis","icon":"https://files.nabox.io/icon/Metis.png"},
        {"chain":"Celo","icon":"https://files.nabox.io/icon/CELO.png"},
        {"chain":"Klaytn","icon":"https://files.nabox.io/icon/Klaytn.png"},
        {"chain":"Aurora","icon":"https://files.nabox.io/icon/aurora_1.png"},
        {"chain":"Gnosis","icon":"https://files.nabox.io/icon/xdai_1.png"},
        {"chain":"Moonriver","icon":"https://files.nabox.io/icon/Moonriver.png"},
        {"chain":"Moonbeam","icon":"https://files.nabox.io/icon/Moonbeam.png"},
        {"chain":"Theta","icon":"https://files.nabox.io/icon/Theta.png"},
        {"chain":"Oasis","icon":"https://files.nabox.io/icon/Oasis-2.png"},
        {"chain":"smartBCH","icon":"https://files.nabox.io/icon/smartBCH.png"},
        {"chain":"Wanchain","icon":"https://files.nabox.io/icon/Wanchain-1.png"},
        {"chain":"HSC","icon":"https://files.nabox.io/icon/Hoo.png"},
        {"chain":"GateChain","icon":"https://files.nabox.io/icon/GateChain.png"},
        {"chain":"Boba","icon":"https://files.nabox.io/icon/BOBA.png"},
        {"chain":"Evmos","icon":"https://files.nabox.io/icon/Evmos.png"},
        {"chain":"Telos","icon":"https://files.nabox.io/icon/Telos.png"},
        {"chain":"Fuse","icon":"https://files.nabox.io/icon/Fuse.png"},
        {"chain":"REI","icon":"https://files.nabox.io/icon/REI.png"},
        {"chain":"TomoChain","icon":"https://files.nabox.io/icon/Tomo.png"},
        {"chain":"CMP","icon":"https://files.nabox.io/icon/CMP.png"},
        {"chain":"Astar","icon":"https://files.nabox.io/icon/astar.png"},
        {"chain":"KavaEVM","icon":"https://files.nabox.io/icon/Kava.png"},
        {"chain":"ETHW","icon":"https://files.nabox.io/icon/ETHW.png"},
        {"chain":"XinFin","icon":"https://files.nabox.io/icon/Xinfin.png"},
        {"chain":"Bitgert","icon":"https://files.nabox.io/icon/brise0001.jpg"},
        {"chain":"PlatON","icon":"https://files.nabox.io/icon/LAT-03.png"},
        {"chain":"Kava","icon":"https://files.nabox.io/icon/KAVA.png"},
        {"chain":"Core","icon":"https://files.nabox.io/icon/core.png"},
        {"chain":"ONTEVM","icon":"https://files.nabox.io/icon/ONT.png"},
        {"chain":"Crypto.org","icon":"https://files.nabox.io/icon/CRO.png"},
        {"chain":"Injective","icon":"https://files.nabox.io/icon/Injective.png"},
        {"chain":"Terra","icon":"https://files.nabox.io/icon/terra.png"},
        {"chain":"ZetaChain","icon":"https://files.nabox.io/icon/zetachain.jpg"},
      ]
    }
  },
  computed: {
    lang() {
      return this.$i18n.locale
    },
    //插件下载链接
    pluginLink() {
      const baseUrl = "https://chrome.google.com/webstore/detail/nabox-wallet/nknhiehlklippafakaeklbeglecifhad?hl=";
      const prefix = this.lang === "cn" ? "zh-CN" : "en";
      return baseUrl + prefix;
    },
    docLink() {
      const baseUrl = "https://nabox.gitbook.io/naboxwallet";
      const prefix = this.lang === "cn" ? "" : "/v/english/";
      return baseUrl + prefix;
    },
    //如何使用Nabox插件
    bottomDocLink1() {
      const baseUrl = "https://nabox.gitbook.io/naboxwallet";
      const prefix = this.lang === "cn" ? "/ru-he-shi-yong-nabox-cha-jian" : "/v/english/how-to-use-nabox-plugin";
      return baseUrl + prefix;
    },
    //DApp连接Nabox插件
    bottomDocLink2() {
      const baseUrl = "https://nabox.gitbook.io/naboxwallet";
      const prefix = this.lang === "cn" ? "/yi-tai-fang-ying-yong-lian-jie-nabox-cha-jian" : "/v/english/ethereum-dapp-connect-nabox-plugin";
      return baseUrl + prefix;
    },
  },
  components: {
    Feature1,
    Feature2,
    Feature3,
    Feature4,
  },
  mounted () {
    /* window.addEventListener("click", () => {
      console.log(444)
      this.showList = false
    }, true) */
  },
  methods: {
    langChange() {
      const lang = this.lang === "cn" ? "en" : "cn"
      this.$i18n.locale = lang;
      localStorage.setItem("lang", lang)
    },
    toggleMenu() {
      this.hideMenu = !this.hideMenu
    },
    showDownList() {
      this.mobildDown = !this.mobildDown
    }
  },
};
</script>

<style lang="less">
@import "Home";
</style>
